body {
    background-color: #f1f1f1;
    overflow-x: hidden;
    font-family: 'PT Sans', sans-serif;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.page--configurateur footer {
    display: none;
}

.page--configurateur {
    background-color: #fff;
}

.header {
    padding: 20px 15px;
    height: 60px;
    background: #fff;
    margin-bottom: 15px;
}

.c-shadow {
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.panel-state {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: center;
    border-radius: 50%;
}

.panel-state.empty {
    background-color: #c5c5c5;
    background-size: 6px;
    background-image: url('../img/tools-right/chevron-white.svg');
}

.panel-state.ok {
    background-color: #17ce4f;
    background-size: 10px;
    background-image: url('../img/tools-right/check-white.svg');
}

.panel-state.disabled {
    background-color: #f93f2b;
    background-size: 3px;
    background-image: url('../img/tools-right/warning-white.svg');
}

.panel {
    position: absolute;
    border-radius: 6px;
    top: 1.5rem;
    width: 320px;
    color: #646464;
    z-index: 2;
}

.select-chevron,
.panel-chevron {
    background: url('../img/tools-right/chevron-grey.svg') no-repeat;
    background-size: 15px;
    display: block;
    height: 50px;
    width: 15px;
    background-position: center;

    transform: rotate(0deg);
    transition: 300ms transform ease;
}

.panel-gear {
    background: url('../img/options-complt/gear-blue.svg') no-repeat;
    background-size: 20px;
    display: block;
    height: 50px;
    width: 20px;
    background-position: center;
} 

.panel.right {
    right: 1.5rem;
}

.panel.left {
    left: 1.5rem;
}

.panel-container {
    position: relative;
    background: #fff;
}

.panel-options {
    display: none;
    padding: 0 12px;
    margin: 0;
    list-style: none;
}

.select,
.panel-heading {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 0 12px;

    cursor: pointer;
    background-color: #fff;
    transition: .05s background-color ease-in;
}

.panel-heading:hover {
    background-color: rgba(247, 247, 247, 0.6);
}

.select,
.panel-heading > div {
    flex: 1;
    display: flex;
    align-items: center;
}

.panel-heading p {
    color: #646464;
    margin: 0;
    display: inline-block;
    font-weight: bold;
    margin-left: 10px;
    pointer-events: none;
}

.panel-label > label {
    margin-bottom: 0;
    pointer-events: none;
}

.panel-item {
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
}

.panel-item > .select {
    margin-bottom: 0;
}

.panel-item.open,
.panel-item.open .panel-heading {
    background-color: #f1f1f1
}

.panel-item.open .panel-options {
    display: block;
}

.panel-option {
    display: flex;
    align-items: center;
    background: #fff;
    border: 2px solid #fff;
    transition: 100ms border-color ease;
    margin: 10px 0;
    box-shadow: 0 1px 3px rgba(0,0,0,0.0), 0 1px 2px rgba(0,0,0,0.14);
    padding: 5px 10px;
}

.panel-option input {
    display: none;
}

.panel-option img {
    width: 60px;
    display: inline-block;
}

.panel-option span {
    color: #646464;
    font-weight: 400;
    flex: 1;
    padding-right: 20px;
    margin-left: 10px;
    display: inline-block;
}

.panel-option.selected {
    border-color: rgb(10, 188, 254)
}

.panel-button-blue {
    background-color: #2d3f7d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    height: 50px;
    padding: 0 12px;
    cursor: pointer;
    font-weight: bold;
}

.panel-button-blue-inner {
    flex: 1;
    text-align: center;
    pointer-events: none;
    user-select: none;
}

.panel-selection {
    background-color: #fff;
    width: 30px;
    height: 30px;
    border: 2px solid #d6d6d6;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.panel-selection > div {
    width: 18px;
    height: 18px;
    background-color: #6cbdf1;
    border-radius: 50%;
    display: none;
}

.panel-option.selected .panel-selection > div {
    display: block;
}

.panel-item.open .panel-chevron {
    transform: rotate(180deg);
}

.select {
    box-shadow: 0 1px 3px rgba(0,0,0,0.0), 0 1px 2px rgba(0,0,0,0.14);
    margin-bottom: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    z-index: 1;
    padding: 0;
}

.select.disabled {
    opacity: 0.8;
    pointer-events: none;
    cursor: not-allowed;
}

.select-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
}

.select.open {
    z-index: 2
}

.select.open .select-options {
    display: block;
}

.select-placeholder {
    font-weight: bold;
    pointer-events: none;
}

.select-options {
    background: #fff;
    position: absolute;
    left: 0;
    top: 50px;
    display: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.0), 0 1px 2px rgba(0,0,0,0.14);
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.select-title {
    color: #283c97;
    font-size: 16px;
    padding: 7px 12px 3px 12px;
    pointer-events: none;
}

.select-item {
    min-height: 30px;
    line-height: 1;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 0;
}

.select-item.selected,
.select-item:hover {
    background-color: #e4e4e4;
}

.select-item input {
    display: none;
}

.select-preview {
    margin-right: 8px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
}

.panel-button-validate {
    background-color: rgb(255, 164, 32);
    color: #fff;
    border: 0;
    appearance: none;
    height: 50px;
    display: block;
    width: 100%;
    font-weight: bold;
}

.panel-button-validate:disabled {
    opacity: .6;
}

.panel-group-blue.open {
    order: 0;
}

.panel-group-blue.open .panel-options-complementary {
    display: block;
    height: 400px;
}

.panel-options-complementary {
    display: none;
    position: relative;
}

.accessoire-group-head {
    display: flex;
}

.accessoire-group-head > p {
    flex: 1;
}

.accessoire-title {
    flex: 1;
    padding-left: 10px;
}

.accessoire-libelle {
    margin-bottom: 0;
    font-weight: bold;
}

.accessoire-image img {
    width: 70px;
    height: auto;
}

.accessoire-detail {
    border: 0;
    appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-top: 5px;
    
    background: url('../img/options-complt/zoom-white.svg') no-repeat;
    background-size: 15px 15px;
    background-position: center;
    background-color: #2d3f7d;
}

.accessoire-group-button {
    margin-top: 1rem;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

p.accessoire-subtitle {
    color: #2d3f7d;
    font-size: 17px;
}

.accessoire-info {
    outline: 0;
    border: 0;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    
    background: url('../img/options-complt/info-white.svg') no-repeat;
    background-size: 10px 10px;
    background-position: center;
    background-color: #6bbdff;
}

.panel-item.is-gear.open .panel-options {
    position: absolute;
    top: 0;
    z-index: 10;
    background: #fff;
    bottom: 0;
    padding: 15px 12px;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    overflow: hidden;
    height: 400px;
}

.accessoire-actions {
    display: flex;
    flex-flow: row wrap;
}

.grid-cell {
    background-color: lightgrey;
    width: 6px;
    height: 6px;
    margin: 1px;
}

.grid-row {
    display: flex;
}

.grid-cell.active {
    background-color: #2d3f7d;
}

.grid {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 5px 0 10px;
    background-color: #fff;
    transition: .05s background-color ease-in;
}

.grid:hover {
    background-color: rgba(247, 247, 247, 0.6);
}

.grid.disabled {
    opacity: 0.4;
    pointer-events: none;
}


.accessoire-buttons {
    flex: 1;
    display: flex;
    align-items: flex-end;
}

.grid-number {
    font-weight: bold;
    margin-bottom: 0;
}

.composition-container {
    display: flex;
    flex-flow: row wrap;
}

.composition {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    padding: 6px 12px;
    background-color: #fff;
    transition: .05s background-color ease-in;
}

.composition:hover {
    background-color: rgba(247, 247, 247, 0.6);
}

.composition p {
    margin-bottom: 0;
    width: 100%;
}

.composition.selected {
    background-color: #e4e4e4;
}

.composition-image {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.disposition-manual {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    cursor: pointer;
    opacity: 1;

    transition: .2s opacity ease-in-out;
}

.disposition-manual span {
    flex: 1;
}

.disposition-manual.disabled {
    opacity: .5;
    pointer-events: none;
}

.disposition-icon {
    margin-right: 15px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:11;
  }

  .c-modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .c-modal-header,
  .c-modal-footer {
    padding: 15px;
    display: flex;
  }

  .c-modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .c-modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .c-modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .2s ease
  }

.c-modal {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    padding: 2rem;
}

.c-modal-image img {
    width: 400px;
    height: auto;
    display: block;
}

.c-modal-image.small img {
    width: 300px;
}

.c-modal-image {
    margin-right: 1rem;
}

h2.c-modal-title {
    font-weight: bold;
}

.c-modal-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.c-modal-description {
    flex: 1;
}

.c-modal-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.c-modal-list li:before {
    content: '›';
    font-size: 24px;
    height: 30px;
    display: inline-block;
    margin-right: 3px;
    color: #2d3f7d;
}

.informations {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    z-index: 1;
}


.informations-title {
    color: #2d3f7d;
    font-size: 2.2rem;
}

.informations-buttons {
    margin-top: 10px;
}

.informations-button {
    background: #fff;
    border: 0;
    padding: .5rem;
}

.informations-button.spaced {
    margin: 0 10px;
}

.informations-button img {
    width: 20px;
}

.informations-button span {
    margin-left: 10px;
}

.canvas-fade-enter,
.canvas-fade-leave-active {
  opacity: 0;
}

.canvas-fade-enter-active,
.canvas-fade-leave-active {
  transition: opacity .2s ease
}

.loader {
    background: white;
    position: absolute;
    width: 100vw;
    z-index: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    height: calc(100vh - 123px);
}

.loader img {
    width: 150px;
}

#renderCanvas {
    opacity: 1;
    transition: opacity 1s ease;
    margin-top: 148px;
    height: 600px;
}

#renderCanvas.hide {
    opacity: 0;
}

.middle {
    position: relative;
}

@-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

.loader img {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }

  .panel-group {
      display: flex;
      flex-flow: column nowrap;
  }

  .o-1 {
      order: 1;
  }

  .o-0 {
      order: 0;
  }

  .message--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .message--warning p {
      margin: 0;
  }

  .actions {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  }

  .c-rounded {
      border-radius: 50%;
  }

  .tippy-popper * {
      margin: 0 !important;
  }
  
  .c-legend {
      padding: 0;
      margin: 0;
      list-style: none;
  }

  .c-legend li {
      display: flex;
      align-items: center;
  }

  .c-legend .panel-state {
      margin-right: 5px;
  }